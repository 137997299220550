body {
  margin: 0;
  font-family: 'Inter', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 19px !important;
  color: #444444 !important;
  background-color: #fdfefe !important;
}

.files-ui-file-mosaic-main-container {
  font-family: 'Inter', serif !important;
  font-size: 13px !important;
}

.form-check-input:checked {
  background-color: #DA146B !important;
  border-color: #DA146B !important;
}

.form-check-input_req {
scale: 1.7;
margin-right: 10px !important;
}

.menu-close-bt {
  cursor: pointer;
  font-size: 2ch;
  color: #444444 !important;
  position: fixed;
  top: -9px;
  left: 925px;
}

ul {
  list-style: circle;
}

.css-13cymwt-control {
  border-radius: 0.375rem !important;
}

.circle {
  margin: 15px auto 15px auto;
  color: #028B42;
  display: flex;
  border: 3px solid #028B42;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.circle2 {
  color: #028B42;
  display: flex;
  border: 3px solid #028B42;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.icon-alert {
  color: #028B42;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}


#section2 .card {
  border: none !important;
  background-color: #028B42;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  border-radius: 11px;
}


.main-button {
  margin-bottom: 2px;
  font-size: 20px;

}

.dropdown-toggle::after {
  content: none !important;
}


#dropdown-menu {
  --bs-btn-border-radius: 8px !important;
}


#dropdown-basic2 {
  --bs-dropdown-border-color: none !important;
  --bs-dropdown-border-radius: 5px !important;
  inset: 10px auto auto 0px !important;
  --bs-dropdown-font-size: 20px !important;
  transform: translate3d(0px, 65px, 0px) !important;
  background-color: rgba(255, 255, 255) !important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .3) inset, 0 .5em 1em rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, .3);
}


#dropdown-basic2 .nav-link {
  color: #2D3A3A;
}

#dropdown-basic3 {
  --bs-dropdown-border-color: none !important;
  --bs-dropdown-border-radius: 5px !important;
  --bs-dropdown-font-size: 20px !important;
  --bs-dropdown-color: #2D3A3A !important;

}

#botton-di,
.dropdown-item {
  padding: 4px !important;
}

.p-logo {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.main-menu {
  padding-left: 25px !important;
  padding-right: 65px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 25px !important;
  font-weight: bold !important;
  border-bottom: none;
  text-transform: uppercase;
  letter-spacing: .02em;
}

/* .main-menu_m.active {
  border-bottom: 2px solid green !important;
} */

.main-menu.active, .main-menu_m.active{
  border-left: 7px solid green !important;
}

.main-menu_m {
  padding-top: 10px !important;
  padding-left: 10px !important;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .02em;
  border-bottom: none;
}

.offcanvas-body {
padding-top: 0px !important;
}

#main-nav {
  flex-direction: column !important;
}

#mobile-nav .nav-link {
  font-size: 21px !important;
  color: black;
  margin: 2px;
}

#logomain {
  /* background: url(/public/mainlogo.webp)center center; */
  background: radial-gradient(circle, rgba(244,245,156,0.23713235294117652) 0%, rgba(231,231,17,0.7049194677871149) 80%);
  background-size: cover;
  position: relative;
  margin-top: 10px;
  padding: 0;
  border-radius: 11px;
}

#logopage {
  /* background: url(/public/mainlogo.webp)center center; */
  background: radial-gradient(circle, rgba(244,245,156,0.23713235294117652) 0%, rgba(231,231,17,0.7049194677871149) 80%);
  background-size: cover;
  position: relative;
  margin-top: 10px;
  padding: 0;
  /* height: 186px; */
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
  border-radius: 11px;
}

.spiner-user-profile {
  padding: 100px;
}

.btn {
  --bs-btn-border-radius: 8px !important;
  font-size: 20px !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.btn-secondary {
  --bs-btn-color: #6c757d !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #fff !important;
  color: #2D3A3A !important;

}

.btn-success {
  --bs-btn-bg: #028B42 !important;
  --bs-btn-border-color: #028B42 !important;
  --bs-btn-hover-bg: #028B42 !important;
  --bs-btn-hover-border-color: #028B42 !important;
  --bs-btn-active-bg: #028B42 !important;
  --bs-btn-active-border-color: #028B42 !important;
  --bs-btn-disabled-bg: #028B42 !important;
  --bs-btn-disabled-border-color: #028B42 !important;
}

.logomain-left-button {
  margin-top: 8px;
  font-size: 24px !important;
  font-weight: 900 !important;

  --bs-btn-bg: #DA146B !important;
  --bs-btn-border-color: #DA146B !important;
  --bs-btn-hover-bg: #DA146B !important;
  --bs-btn-hover-border-color: #DA146B !important;
  --bs-btn-active-bg: #DA146B !important;
  --bs-btn-active-border-color: #DA146B !important;
  --bs-btn-disabled-bg: #DA146B !important;
  --bs-btn-disabled-border-color: #DA146B !important;
}


#logomain h1 {
  margin: 0 0 10px 0;
  font-weight: 700;
  line-height: 56px;
  margin-left: 80px;
  line-height: 1.5ch;
}

#logomain h2 {
  margin-bottom: 10px;
  margin-left: 80px;
}

.textx {
  text-decoration: underline;
  cursor: pointer;
  color: #028B42 !important;
}

.nav-scroller {
  overflow-x: auto;
  flex-wrap: nowrap !important;
  white-space: nowrap;
  position: relative !important;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.nav-pills .nav-link {
  border-radius: .25rem;
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, .6);
  margin: .5rem !important;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.6rem;
  font-size: 1.2rem !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #028B42 !important;
}

.py-md-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;

}


.modal-info {
  font-size: 0.9rem;
}

.pro-portal {
  font-size: 1.4rem;
  line-height: 35px;
}

.title--howwork {
  font-size: 1.35rem;
}

.pro-portal2 {
  font-size: 1.1rem;
  line-height: 35px;
}

.pro-portal-abilities-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

.pro-portal-ability-item {
  flex-basis: calc(50% - 30px);
  box-sizing: border-box;
  margin: 15px;

  padding: 20px 20px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 32px;
}

@media (max-width: 992px) {
  .pro-portal-ability-item {
    flex-basis: 100%;
  }
}

.info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.up-left {
  border: 2px #028B42;
  box-shadow: 0px 0px 4px #30945f;
  margin: 20px 20px;
  padding: 20px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 29px;
}

.up-right {
  border: #028B42 2px;
  box-shadow: 0px 0px 4px #30945f;
  border-radius: 8px;
  margin: 20px 20px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 29px;
}

.down-left {
  border: 2px #028B42;
  box-shadow: 0px 0px 4px #30945f;
  border-radius: 8px;
  margin: 20px 20px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 29px;
}

.down-right {
  border: #028B42 2px;
  box-shadow: 0px 0px 4px #30945f;
  margin: 20px 20px;
  padding: 20px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 29px;
}

.info-icon {
  color: #028B42;
  margin-right: 20px;
}

.info-text {
  line-height: 29px;
  margin-top: 100px;
  margin-bottom: 107px;
}

.pro_portal_modal {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.info-text {
  margin: 80px 0;
}

.politic-conf {
  color: #0d6efd;
  cursor: pointer;
  text-decoration: underline;
}

.modal-window {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
}

.modal-window-show {
  transform: scale(1);
}

.modal-content {
  border-radius: 10px;
  background-color: white;
}

.modal-close {
  width: 120px;
  margin: 0 auto;
}


@media (min-width: 300px) and (max-width: 1190px) {
  .info-container {
    grid-template-columns: 1fr;

  }
}

/* proportal end */

.nowrap {
  white-space: nowrap;
  position: relative;
}


.img-cc {
  height: auto;
}


.icon-footer-top {
  margin-bottom: 19px;
  margin-top: 15px;
}

.icon-footer-top-main-menu {
  margin-bottom: 2px;
  color: #444444;
}

.text-footer-text {
  cursor: pointer;
}

.iconmain-text {
  font-size: 27px;
}


.footer-bottom {
  background: #0F5E34;
  color: #fff;
}

.logo {
  line-height: 1.8ch;
  color: #444444 !important;
  text-decoration: none !important;
}

.xxx21 {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  color: color(display-p3 0.9254 0.3872 0.2156);

}

.xxx3 {
  font-size: 23px !important;
  font-weight: bold;
  line-height: 30px;
}

.xxx4 {
  font-size: 25px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

#bt-my-1580 {
  --bs-btn-bg: #DA146B !important;
  --bs-btn-border-color: #DA146B !important;
}

.listp1 {
  padding-left: 2rem !important;

}

btn:hover {
  color: #DA146B !important;
  background-color: #DA146B !important;
  border-color: #DA146B !important;
}

.btn-secondary {
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #fff !important;
  --bs-btn-hover-bg: #fff !important;
  --bs-btn-hover-border-color: #fff !important;
  --bs-btn-active-bg: #fff !important;
  --bs-btn-active-border-color: #fff !important;
  --bs-btn-disabled-border-color: #fff !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #DA146B !important;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #DA146B !important;
  --bs-btn-hover-border-color: #DA146B !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #DA146B !important;
  --bs-btn-active-border-color: #DA146B !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #DA146B !important;
  --bs-btn-disabled-border-color: #DA146B !important;
}

.ways-transfer:hover {
  color: rgba(0, 0, 0, .6) !important;
}

.ways-transfer.active:hover {
  color: #fff !important;
}


.profile-icon {
  color: #028B42;
  font-size: 27px !important;
  margin-right: 10px;
}

.profile-icon-m {
  color: #028B42;
  font-size: 30px !important;
  margin-right: 7px;
}

#usermenu_b,  #usermenu_b_m2{
  color: #444444;
}

@media (min-width: 992px) {

  .m1 {
    display: none;
  }

  /* .news-block:hover {
    background-color: #f3f7f5 !important; 
    scale: 101%;
  } */

  .btn:hover {
    color: #DA146B !important;
    background-color: #DA146B !important;
    border-color: #DA146B !important;
    color: #fff !important;
  }

  #usermenu_b:hover {
    background-color: white !important;
    border-color: white !important;
    color: #444444 !important;
  }
  #usermenu_b_m2:hover {
    background-color: white !important;
    border-color: white !important;
    color: #444444 !important;
  }


  .bt-red:hover {
    background-color: #F986B9 !important;
    border-color: #F986B9 !important;
    color: #fff !important;
  }

  .main-menu:hover {
    color: #DA146B !important;
  }

  .ways-transfer:hover {
    background-color: #DA146B !important;
    color: #fff !important;
  }

  .ways-transfer.active:hover {
    cursor: default !important;
  }

}

.repeirs-alarm-user-profile {
  color: red;
  font-size: 15px;
  margin-left: 10px !important;
  margin-top: 27px !important;
  position: absolute;
}

.repeirs-alarm-user-profile2 {
  color: red;
  font-size: 17px;
}

@media (max-width: 992px) {

  .remontworks-icon {
    width: 50px !important;
    height: 50px !important;
    scale: 130%;
    background-size: 35px 35px !important;

  }

  .repeirs-alarm {
    font-size: 20px !important;
    margin-left: 33px !important;
    margin-top: 23px !important;
  }

  .logomain-left-button {
    margin-top: 20px !important;
    margin-bottom: 40px;
  }


  .m2 {
    display: none !important;
  }

  .col-12 {
    width: 80% !important;
  }


  #logomain-left-button {
    --bs-btn-padding-x: 1.1rem !important;
    --bs-btn-padding-y: 0.5rem !important;
    font-weight: 900;
    margin-top: 30px;
  }

  #logomain h1 {
    text-align: center !important;
    margin-bottom: 0px !important;
    margin-left: 0 !important;
    line-height: 2.1ch;
  }


  #logomain h2 {

    text-align: center !important;
    margin-bottom: 9px !important;
    margin-left: 0 !important;
  }

  .getq {
    text-align: center;
    margin-bottom: 0px !important;
    margin-left: 0 !important;
  }

  .p-logo {
    padding-top: 2rem !important;
    padding-bottom: 3rem !important;
  }

}


.problem-block1_wrap .icon {
  width: 70px;
  height: 70px;
  margin: 0 auto 5px auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.problem-block1_wrap {
  height: 80px;
  margin-bottom: 45px;
  text-align: center;
  cursor: pointer;
}

.activer .icon {
  border-color: #DA146B !important;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
  background-size: 40px 40px;
}

.activer {
  color: #DA146B !important;
  /* font-weight: bold; */
}

.activer2 {
  background-color: #DA146B !important;
}

.remontworks-icon {
  width: 67px;
  height: 67px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 67px 67px;
}

.logo-text {
  line-height: 2.65ch;
}


.userprofile-problem-icon {
  width: 85px;
  height: 85px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.repeir-date-m {
  margin-bottom: 15px;
}

.repeirs-alarm {
  color: red;
  font-size: 30px;
  margin-left: 50px;
  margin-top: 31px;
  z-index: 9999;
}

.img-problem_wrap {
  height: 180px;
  text-align: center;
}

.addevent-groupe {
  cursor: pointer;
  color: #028B42;
}

.addevent-groupe2 {
  cursor: pointer;
  color: #028B42;
}


.addevent-groupe:hover {
  color: #DA146B;
}


.addevent-groupe2:hover .rep-img {
  color: #DA146B;
  background: url(/public/my_repairh.png)center center;
  background-size: cover;
}

.addevent-groupe2:hover {
  color: #DA146B;
}

.rep-img {
  width: 40px;
  height: 40px;
  background: url(/public/my_repair.png)center center;
  background-size: cover;
}

.addevent-text {
  font-size: 26px;
  padding-bottom: 10px;
  display: inline;
}
@media (max-width: 575px) {
  .news-img{
    max-height: none !important;
  } 
}
@media (min-width: 990px) and (max-width: 1200px) {
  .news-img{
    max-height: 300px !important;
  } 
}

@media (max-width: 991px) {

  .news-img{
    max-height: 320px;
  }


  .addproblem-text {
    text-align: left !important;
    font-size: 1.2rem !important;
  }

  .addevent {
    font-size: 43px !important;
    top: 122px !important;
  }

  .img-problem_wrap {
    height: auto;
    margin-bottom: 15px;
    text-align: left;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-left: 20px;
  }



  .problem-block1_wrap {
    height: auto;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .problem-block1_wrap .icon {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 10px;
    display: inline-flex;
    height: 60px;
    flex: 0 0 60px;
    vertical-align: middle;
  }

  .img-problem .title {
    white-space: normal;
    display: inline-flex;
    vertical-align: middle;
  }
}

.bbb {
  width: 100%;
}

.main-button2 {
  margin-bottom: 2px;
  margin-left: 6px;
  font-size: 20px;

}

#drmainbutton .dropdown-menu {
  text-align: center;
  font-size: 19px;
  width: 100%;
  --bs-dropdown-border-color: none !important;
  --bs-dropdown-border-radius: 8px !important;
}


#offcanvasNavbar-expand-false {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.85);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .3) inset, 0 .5em 1em rgba(0, 0, 0, 0.6);
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, .3);
}

#menu_mb {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.90);
  /* box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .3) inset, 0 .5em 1em rgba(0, 0, 0, 0.6); */
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, .3);
}

.navbar-header {
  margin-top: 10px;
}

.rep-icon {
  font-size: 120px;
}

.rep2-icon {
  font-size: 100px;
  color: #028B42;
}

.rep3-icon {
  font-size: 30px;
  color: #028B42;
}

.notfound {
  margin-top: 2%;
  margin-bottom: 10%;
}

.search-icon {
  font-size: 21px;
}

.exitCabinet {
  font-size: 19px;
  color: #444444;
  text-decoration: none !important;
}

.exitCabinet2 {
  font-size: 22px;
  color: red;
  text-decoration: none !important;
  margin-left: 5px;
}

#loginFormAlert {
  --bs-alert-color: none !important;
  --bs-alert-bg: none !important;
  --bs-alert-border-color: none !important;
  color: red !important;
}

.alert {
  --bs-alert-color: none !important;
  --bs-alert-bg: none !important;
  --bs-alert-border-color: none !important;
  color: red !important;
  padding: 0 0 0 0 !important;
  margin-left: 14px;
  font-size: 15px !important;

}

.non-street-user-profile {
  --bs-alert-color: none !important;
  --bs-alert-bg: none !important;
  --bs-alert-border-color: none !important;
  color: red !important;
}


.modal-footer {
  /* border-top: none !important; */
  justify-content: space-between !important;
  flex-wrap: nowrap !important;
}

.grecaptcha-badge {
  z-index: 9999;
}

.login_modal_button3 {
  width: 65%;
}

.login_modal_button {
  color: #028B42;
  cursor: pointer;
  font-size: 16px !important;
}

.login_modal_button2 {
  font-size: 22px !important;
  color: #028B42;
  cursor: pointer;
}
.login_modal_button2:hover, .login_modal_button:hover{
  color: #DA146B;
}

.label {
  font-size: 15px !important;
  color: #797878 !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #028B42 !important;
  --bs-btn-border-color: #028B42 !important;
}

.btn-close {
  font-size: 27px;
  color: #028B42;
}



.repeir-card .card-header {
  background-color: rgba(207, 231, 218, 0.25) !important;
}

.card-footer {
  background-color: white !important;
}

.rememberPaswordResult {
  margin: 15px auto 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 7px solid green;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  font-size: 85px;
  color: green;
  display: block;
  text-align: center;
}

.sad-icon {
  margin: 15px auto 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 100px;
  color: red;
  display: block;
  text-align: center;
}

.sad-icon2 {
  color: red;
  font-size: 25px;
  text-align: center;
}


.fail-email-conf {
  margin: 15px auto 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 7px solid red;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  font-size: 85px;
  color: red;
  display: block;
  text-align: center;
}

.rememberPaswordResult2 {
  font-size: 25px;
  text-align: center;
}

.block {
  padding: 15px 20px;
  background: #fff;
  margin: 0 auto;
}

.labal-alert {
  margin-bottom: 0rem !important;
  color: red;
  font-size: 15px !important;
}

.label-registration {
  font-size: 13px !important;
}

.label-user-profile-gender {
  font-size: 20px !important;
}

.label-need-alarm {
  font-size: 15px !important;
}

.form-control {
  padding: .4rem .75rem !important;
  font-size: 1.3rem !important;
}

.footer-text-logo {
  line-height: 1.8ch;
  text-decoration: none;
}

.footer-fontsize {
  font-size: 15px !important;
}


.card {
  --bs-card-border-color: #a1d2b8 !important;
}

.css-16s2c0z-control:hover {
  border-color: #DA146B !important;
}

.infinite-scroll-component {
  overflow: visible !important;
}

.cursorp {
  cursor: pointer;
}

.cabinet-header {
  background-color: #028B42;
  height: 60px;
  border-radius: 7px;
}

.addevent {
  font-size: 30px;
  color: #028B42;
  position: absolute;
  top: 160px;

}

.accordion-button:not(.collapsed) {
  background-color: #f3f9f6 !important;
  color: #444444 !important;
  box-shadow: none !important;
}

.accordion-body .collapse .show {
  border: none !important;
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #a1d2b8 !important;
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 0.7rem !important;
  --bs-accordion-btn-padding-y: 0.6rem !important;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon-width: 1.1rem !important;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-focus-border-color: white !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-body-padding-x: 0.8rem !important;
  --bs-accordion-body-padding-y: 0.6rem !important;
  --bs-accordion-active-color: white !important;
  --bs-accordion-active-bg: #e7f1ff !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon) !important;
}


.statusicon {
  color: green !important;
  font-size: 22px;
}

.statusicon_no {
  color: rgb(11, 18, 214) !important;
  font-size: 23px;
}

.statusicon_RemoveDone {
  color: red !important;
  font-size: 23px;
}

.statusicon_wait {
  color: #444444 !important;
  font-size: 19px;
}

.statusicon_wait2 {
  color: #444444 !important;
  font-size: 22px;
}


.user-request_bottom {
  background-color: #f4f4f4;
  font-size: 15px !important;
  border-radius: 6px;
}

.requestbody {
  font-size: 16px !important;
}

.address-card-profile {
  padding-bottom: 5px;
}

.profile-icon3 {
  font-size: 30px !important;
  margin-right: 10px;
}

.profile-icon_rep {
  font-size: 21px;
}

.create-request-clouse-icon {
  font-size: 30px;
  color: white !important;
  cursor: pointer;
  margin-bottom: 4px !important;
  display: block;
}

.navmy1580 {
  background-color: #028B42;
}

.profile-icon_rep_2 {
  font-size: 30px;
  margin-bottom: 4px;
  margin-right: 10px;
}

.profile-icon_rep-m {
  color: green;
  font-size: 21px;
}

.card-userprofile {
  background-color: #edf7f2 !important;
  border-color: #edf7f2 !important;
  --bs-card-spacer-y: 0.3rem !important;
  --bs-card-spacer-x: 0.8rem !important;
}


.list-group-item,
.card-userprofile {
  padding: 4px !important;
}

.btn-link {
  --bs-btn-font-weight: 700 !important;
  --bs-btn-color: green !important;

}

.card-user-2 {
  padding-bottom: 5px;
}

.setting-title {
  font-size: 10px;
}

.main-button-login {
  font-size: 28px;
}


.main-button-login2 {
  cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {

  background-color: white !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #DA146B !important;
  background-color: #fff !important;
}

.addproblem-text {
  text-align: center;
  font-size: 0.95rem;
  line-height: 1.3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}

.badge {
  padding: 0.5rem !important;
  --bs-badge-font-weight: 800 !important;
  cursor: pointer;
  margin: 5px 5px 5px 5px;
  background-color: #028B42;
}

.creare-request-title {
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: .16rem;
}

.creare-request-title-icon {
  color: green;
  font-size: 35px;
  margin-top: 5px;
}

.cp {
  cursor: pointer;
}

.file-item-full-container-container {
  font-family: 'Inter' !important;
}

.file-item-full-container-container .file-item-name {
  letter-spacing: 0.05em !important;
}

.upload_alert {
  font-size: 0.9rem;
}

.bd-callout-info {
  border-left-color: green;
}

.bd-callout {
  border-left-width: 2px;
  border-left-width: .24rem;
  border-radius: .24rem;
  padding: 0.9rem;
  margin-top: 0.7rem;
  margin-bottom: 1.25rem;
  border: 1px solid #e9ecef;
  border-left-color: green;
}

.confirm-title {
  font-size: 14px !important;
  text-decoration: underline;
}

.non-house-create-request {
  margin-left: 0 !important;
  margin-top: 5px;
}

.css-13cymwt-control {
  min-height: 46px !important;
  cursor: pointer !important
}

.mySelect__value-container {
  height: 46px;
}

.news__title {
  margin-top: 3px;
  font-size: 1.3rem;
  line-height: 1.333;
  font-weight: 700;
}

.title_text__icon {
  font-size: 0.9rem;
  line-height: 1.6;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: bold;
  margin-top: 8px;
  text-transform: uppercase;
}

.news__info {
  margin-top: 5px;
  font-size: 0.9rem !important;
  line-height: 1.5 !important;
  overflow: hidden;
  display: -webkit-box; 
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #797979;
}


.news__time {
  font-size: 0.93rem;
  font-weight: 900;
  color: #a6a6a6;
 display: flex;
 align-items: flex-end;
}


.active>.page-link,
.page-link.active {
  z-index: 3;
  color: white !important;
  background-color: green !important;
  border-color: green !important;
}

.page-link {
  color: green !important;
  background-color: white !important;
}


.news-next-icon {
  font-size: 18px;
  color: #6c757d !important;
}

.news-next {
  font-size: 15px !important;
  color: #6c757d !important;
}

.news--next {
  font-size: 1rem !important;
}

.news-desc {
  margin-top: 20px;
  padding: 13px;
  border-radius: 12px;
  background-color: #f2f2f2;
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-weight: 800;
}

.news-date {
  font-size: 14px;
}

.news-back-icon {
  display: inline;
  color: #028B42;
  font-size: 30px !important;
}

.news-back {
  cursor: pointer;
  font-size: 23px;
  color: #028B42 !important;
  text-decoration: none !important;
}

.news--nav:hover {
  text-decoration: underline;
}

.news--nav {
  color: #6c757d !important;
}


.user-refresh-icon {
  color: #028B42;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 7px;
}

.user-profile-remontworks-title {
  color: #028B42;
  font-size: 1.7rem;
  cursor: pointer;
}

.user-refresh-icon:hover {
  color: #DA146B;
}

.form-control:focus {
  border-color: #c2e6d3 !important;
  box-shadow: 0 0 0 .05rem #c9d8d0 !important;
}


.stat-wrap .stat-wrap1 {
  background: #028B42;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 auto 3px auto;
  color: white;
  font-weight: bold;
  line-height: 60px;
}

.wrap {
  display: block;
  position: relative;
  height: 100px;
  margin-bottom: 60px;
  text-align: center;
  font-weight: bold;
}

.wrap1 .wrap11 {
  margin: 0 auto 3px auto;
  background: #028B42;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  color: white;
  font-weight: bold;
  line-height: 60px;
}

.wrap1 {
  display: block;
  position: relative;
  height: 150px;
  margin-bottom: 60px;
  text-align: center;
  font-weight: bold;
}

.daterangepicker .ranges li.active {
  background-color: #028B42 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #028B42 !important;
}

.daterangepicker .ranges li {
  font-size: 15px !important;
}

.pass-wrapper {
  position: relative;
  display: flex;
}

.pass-wrapper i {
  position: absolute;
  top: 20%;
  right: 4%;
}

i:hover {
  color: #028B42;
  cursor: pointer;
}

/* карта */


.requests-map {
  height: 80vh;
  z-index: 0;
  border-radius: 5px;
}

/* конец карта */


/* techsupport start*/

.move-to-tech-support {
  cursor: pointer;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: underline;
}


.info-tech-support {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.tech-support-container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.info-tech-support {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.block-info-icon {
  margin-top: 32px;
  margin-left: 26px;
  margin-right: 32px;
  margin-bottom: 66px;
}

.block-info-question {
  margin-top: 28px;
  font-size: 28px;
  line-height: 34px;
}

.block-info-text1 {
  margin-top: 12px;
  line-height: 29px;
}

.block-info-text {
  line-height: 29px;
}

.first-block-info {
  display: flex;
  box-shadow: 0px 0px 4px #0F5E34;
  border-radius: 8px;
  margin-bottom: 60px;
}

.second-block-info {
  display: flex;
  box-shadow: 0px 0px 4px #0F5E34;
  border-radius: 8px;
  margin-bottom: 60px;
  padding-bottom: 28px;
}

.third-block-info {
  display: flex;
  box-shadow: 0px 0px 4px #0F5E34;
  border-radius: 8px;
  margin-bottom: 60px;
  padding-bottom: 28px;
}

.fourth-block-info {
  display: flex;
  box-shadow: 0px 0px 4px #0F5E34;
  border-radius: 8px;
  margin-bottom: 60px;
  padding-bottom: 28px;
}

.fifth-block-info {
  display: flex;
  box-shadow: 0px 0px 4px #0F5E34;
  border-radius: 8px;
  margin-bottom: 60px;
}

/* techsupport end */

.news-spiner {
  padding-top: 4rem !important;
  padding-bottom: 25rem !important;
}

.dropdown-toggle::after {
  display: none;
}

.main--m {
  font-size: 50px !important;
  color: #444444 !important;
}

.request-map {
  border-radius: 5px;
  border: 2px solid #a1d2b8;
}

.icon-request-on-select {
  width: 60px;
  padding: 13px;
}

.mySelectrec__value-container {
  height: 55px;
}


.mySelectrec__control {
  background-color: rgba(207, 231, 218, 0.25) !important;
  font-size: 18px !important;
  border: 2px solid #a1d2b8 !important;
  font-weight: bold;
}

.mySelectrec__placeholder {
  font-size: 18px !important;
  color: #444444 !important;
  margin-left: 10px !important;
  font-weight: bold;
}

.request-on_wrap {
  height: auto;
  text-align: left;
  cursor: pointer;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}


.title_m_r {
  line-height: 1.3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-left: 15px;
  font-size: 1.2rem;
}


.w_rap {
  text-align: center;
}

.requests-wrap_r_title {
  font-size: 1.4rem;
}

.w_rap .wrap11_r {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 110px;
  height: 110px;
  margin: 0 auto 0px auto;
  font-size: 1.6rem;
}

.wrap_border_green {
  color: white !important;
  background-color: #028b42d9 !important;
}

.wrap_border_yellow {
  color: white !important;
  background-color: #fbc02cdb !important;
}

.request-popup {
  font-size: 14px;
}

.request-popup-header {
  color: white;
  background-color: #028B42;
  border-radius: 6px;
}

.request-popup-title {
  font-size: 1.1rem;
}

.request-popup-title-h {
  font-size: 1.2rem;
}

.request-popup-header-col-ye {
  color: #444444 !important;
  background-color: #fbc02d !important;

}

.request-popup-status {
  background-color: #f7f6fb;
  border-radius: 6px;
  border: 1px !important;
  border-color: #dbdedc !important;
  border-style: solid !important;
}

.request-popup-header2 {
  padding: 6px;
  font-size: 0.93rem !important;
}

.request-popup-header-t {
  font-size: 1.2rem;
  line-height: 1.3;
}

.request-popup-header-t2 {
  font-size: 1rem;
  line-height: 1.3;
}

.request-popup-header-title {
  font-size: 14px;
}

.request-popup-header-color-yellow {
  color: #fbc02d !important;
}

.daterangepicker {
  font-family: 'Inter' !important;
}

.cancelBtn,
.applyBtn {
  font-weight: 300 !important;
}

@media (max-width: 563px) {

  .title_text__icon {
    font-size: 0.95rem !important;
   
  }

  .daterangepicker {
    bottom: 0 !important;

    width: 100vw !important;

    height: 185px !important;

    border: none !important;
    border-radius: 0px !important;

  }

  .daterangepicker:before {
    top: 0px !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: none !important;
  }

  .daterangepicker .drp-calendar {
    min-width: 100% !important;
  }

  .daterangepicker .ranges {
    margin-left: 20px !important;

  }

  .daterangepicker.show-calendar .ranges {
    margin-top: 0px !important;

  }

  .daterangepicker .ranges li {
    font-size: 19px !important;
    padding: 10px 10px 10px 10px !important;
  }


  .daterangepicker .drp-buttons {
    padding: 9px !important;
    font-size: 10px !important;
    background-color: white;
    box-shadow: 0 4px 2px -2px #ddd;
  }

  .drp-selected {
    display: none !important;
  }

  .daterangepicker .drp-calendar.right {
    padding: 0px !important;
  }

  .daterangepicker .drp-calendar.left {
    padding: 0px 0 0px 0px !important;
    border-top: 1px solid #ddd;
    margin-top: 10px;

  }

}


.groupe_marker {
  margin-bottom: 2 !important;
  margin-top: 2px;
  font-size: 0.9rem;
  min-height: 0 !important;

}

.card-stat {
  background-color: #028B42;
  --bs-card-border-color: #028B42;
  color: white !important;
  border-radius: 11px !important;
}

.card-stat-3 {
  background-color: #D97706 !important;
  --bs-card-border-color: #D97706 !important;
}

.card-stat-2 {
  background-color: #1E3A8A !important;
  --bs-card-border-color: #1E3A8A !important;
}

.card-title-results {
  font-weight: 100 !important;
  font-size: 24px !important;
}

.card-title-results-d {
  font-weight: bold !important;
  font-size: 38px !important;
}

.card-title-results-d2 {
  font-weight: bold !important;
  font-size: 38px !important;
}

.spiner_map {
  position: absolute;
  z-index: 999999 !important;
}

.user--files {
  width: 90px;
  height: 90px;
  border-radius: 7px;
}

.user--app-title {
  background-color: #f4f4f4;
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
}


.grecaptcha-badge { visibility: hidden; }

.captcha_p {
  color: #8c8d8e;
  font-size: .75rem!important;
  line-height: 1.5;
  margin-left: 20px;
  margin-right: 20px;
}

.nohead{
  cursor: default !important;
}

.alert-message
{
  margin: 20px 0;
  padding: 20px;
  border: 1px solid;
}
.alert-message p:last-child
{
  margin-bottom: 0;
}
.alert-message-success
{
  background-color: #f8faf7;
  border-color: #aac5aa;
  border-radius: 7px;
}

#confirm_deleteuser_b:disabled  {
  color: #a6a5a5;
  border-color: #eee;
  background-color: #eee;
  cursor: default;

}


.newslist-icon {
  color: #028B42;
  display: flex;
  width: 90px;
  height: 90px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-wrap: wrap;
}

.newslist-icon-img {
  object-fit: cover;
}

.newslist-header {
  align-items: center;
  display: flex;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}

.newslist-item{
    border-top: 1px solid rgba(0,0,0,.1);
    margin-bottom: 0;
    padding-bottom: 14px;
    padding-top: 14px;
    cursor: pointer;
}

.newlist-footer{
  font-size: 14px;
  line-height: 1.2857;
  font-weight: 700;
  color: #a6a6a6;
  align-items: flex-end;
  display: flex;
}

.newslist-text {
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
  font-weight: 400;
  line-height: 1.3;
  font-size: 17px;
  margin-bottom: 0;
}

.news-text-wrap{
  display: flexbox;
}

.news-img{
  width: 100%;
  object-fit: fill;
  /* height: min-content; */
  max-height: 210px;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  border-radius: 10px;

}

.news--body{
  padding: 0px !important;
  --bs-card-border-color: #fdfefe !important;
}

#news--card{
  --bs-card-border-color: #fdfefe !important;
  margin-bottom: 18px;
}

.more_news_hover:hover .more_news {
  animation: spin 1.5s ease-in-out; 
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* .image-placeholder {
  background-color: #f2f2f2;
  width: 100%;
  border-radius: 10px;
} */

  .spinner-container {
    background-color: #f0f0f0; 
    min-height: 207px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .spinner {
    border: 4px solid #ffffff; /* Светлый фон */
    border-top: 4px solid rgb(115, 170, 115); /* Цвет вращающейся части */
    border-radius: 50%; /* Круглая форма */
    width: 50px; /* Ширина спиннера */
    height: 50px; /* Высота спиннера */
    animation: spin 1s linear infinite; /* Анимация вращения */
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .spinner-container.hidden {
  display: none;
}

.news-img {
  transition: opacity 0.3s ease;
}

.more_news_hover {
  color: #4fa94d;
  cursor: pointer;
}

.arrow-right::after {
  content: " \2192";
  font-size: 20px;
  margin-left: 7px;
}
